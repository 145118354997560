<template>
  <div class="popup_wrap" style="width:850px;height:600px;">
    <!-- popup_wrap : style="width:600px; height:500px;" 참고용, 개발시 제거 -->
    <button
      type="button"
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <div class="popup_cont">
      <!-- popup_cont -->

      <h1 class="page_title">개인정보 취급방침</h1>

      <div class="content_box mt10">
        <!-- content_box -->
        <div class="privacy_group">
          <!-- contents.css에 privacy_group 관련 css 추가 되었음! 1382~1382 -->
          <p>
            고려해운은 온라인상에서 귀하께서 회원 가입시에 제공한 개인정보와 귀하의 서비스 이용에서 발생되는 모든 개인정보를 매우 중요하게 생각하고 있습니다.<br>
            본사는 『정보통신망 이용 촉진 등에 관한 법률』을 준수하고 있으며, 정보통신부가 제정한 『개인정보보호방침』에 따라 회원님이 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 어떠한 조치가 취해지고 있는지 알려 드립니다.
            아울러 관련 법률, 정부지침 변경, 내부 신규서비스 추가 및 회사 정책 변경에 따른 『개인정보보호방침』 변동 사항을 홈페이지 첫 화면에 공개함으로써 회원님이 언제나 수시로 확인 하실 수 있도록 조치하고 있습니다.
          </p>
          <div class="guide_txt_wrap mt20">
            <div class="tit">이 개인정보보호방침의 순서는 다음과 같습니다.</div>
            <div class="guide_txt">
              <p class="mt10">
                1. 개인정보의 동의, 수집 방법, 수집 항목 및 이용목적<br>
                2. 목적 외 사용 및 제3자에 대한 제공 및 공유<br>
                3. 개인정보의 보유 및 이용기간<br>
                4. 개인정보 파기 절차<br>
                5. 이용자 및 법정대리인의 열람 및 정정<br>
                6. 개인정보 자동 수집 장치의 설치,운영 및 거부<br>
                7. 개인정보관리책임자<br>
                8. 개인정보보호를 위한 기술적 대책<br>
                9. 개인정보의 위탁처리<br>
                10. 의견수렴 및 불만처리<br>
                11. 아동의 개인정보보호<br>
                12. 개인정보보호에 대한 책임의 소재 확인<br>
                13.정책 변경에 따른 공지의무
              </p>
            </div>
          </div>

          <h2>1. 개인정보의 동의</h2>
          <p>
            고려해운은 회원가입은 없지만 예약관련으로 개인정보를 받아야 하는 경우가 있습니다. 이때 개인정보의 외부유출을 막기 위해 음성적인 게시판을 사용합니다.<br>
            예약 및 기타 이벤트 진행 시 받는 개인정보는 이름, 주민번호, ID, Password, 주소, 전화번호, 이메일, 필명, 직업 등이 있습니다.
          </p>
          <div class="ml15 mt15">
            ▷ 이름, 주민번호, ID, Password, 필명<br>
            - 제한적 실명제 적용과 서비스 이용을 위한 본인 확인 절차에 사용<br>
            - 개인정보 보호(필명 사용)<br><br>

            ▷ 주소, 전화번호, 이메일, 직업<br>
            - 서비스의 이행<br>
            - 기타 새로운 서비스<br>
            - 홈페이지 서비스 이용 및 기타 업무상의 서비스 이용
          </div>

          <h2>2. 목적 외 사용 및 제3자에 대한 제공 및 공유</h2>
          <p>
            고려해운은 회원 동의 없이 개인정보를 외부에 제공, 공유하지 않는 것을 원칙으로 하고 있습니다. 다만, 더 나은 서비스의 제공의 필요에 의해 회원님의 개인정보를 제휴사 또는 협력업체에게 제공하거나 또는 제휴사나 협력업체들과 공유할 경우에는 회원의 사전 동의를 얻고, 그 사용 범위 또한 고지한 범위 내에서 사용하도록 관리하고 있습니다.<br>
            아래와 경우에는 관련 법령 및 규정에 의거, 예외로 합니다
          </p>
          <div class="ml15 mt15">
            ㄱ. 법령에 규정에 의거, 적법한 법적 절차를 밟은 경우<br>
            ㄴ. 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우<br>
            ㄷ. 서비스 제공에 따른 요금정산, 상거래 등의 이행을 위해 부득이하게 추가 정보가 필요한 경우<br>
            ㄹ. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 가공하여 제공하는 경우<br>
            ㅁ. 금융실명거래 및 비밀보장에 관한 법률, 신용정보의 이용 및 보호에 관한 법률, 전기통신기본법, 전기통신사업법, 지방세법, 소비자보호법, 한국은행법, 형사소송법 등 법률에 특별한 규정이 있는 경우<br>
            ㅂ. 전기통신기본법 등 기타 법률의 규정에 의거 국가기관이 요구하는 경우
          </div>

          <h2>3. 개인정보의 보유 및 이용기간</h2>
          <p>
            원칙적으로, 회원의 개인 정보는 회원으로서 고려해운 서비스(www.kmtc.co.kr) 를 제공 받는 동안 본사에서 지속적으로 보유, 서비스 제공을 위해 이용하게 됩니다.<br>
            또한, 개인정보 수집목적 또는 제공받은 목적이 달성된 경우에도 상법 등 관계 법령의 규정에 의하여 보존할 필요성이 있는 경우에는 예외로 합니다.
          </p>

          <h2>4. 개인정보 파기 절차</h2>
          <p>
            고려해운 고객이 특별한 경우 개인정보 소멸을 요청한 경우와 개인정보의 수집 또는 제공받은 이용 목적이 달성된 경우에 수집된 개인의 정보는 재생할 수 없는 방법에 의하여 개인정보 DataBase, 백업파일에서 완전히 삭제되며 어떠한 용도로도 열람 또는 이용할 수 없도록 처리됩니다.
          </p>

          <h2>5. 이용자 및 법정대리인의 열람 및 정정</h2>
          <p>
            고려해운 회원 및 회원의 법정대리인은 언제든지 등록되어 있는 개인정보를 직접 조회하거나 수정할 수 있으며 가입해지를 하실 수 있습니다. 귀하의 개인정보에 대한 열람 또는 정정을 하고자 할 경우에는
            진행하셨던 예약이나 이벤트를 삭제하거나 삭제요청 할 수 있습니다.<br>
            혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체없이 조치 하겠습니다.<br>
            회원의 요청에 의해 해지 또는 삭제된 개인정보는 '개인정보의 보유 및 이용기간'에 명시된 바에 의해 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다. 또한 귀하가 개인정보의 오류에 대한 정정을 요청한 경우, 정정을 완료하기 전까지 당해 개인정보를 이용하지 않습니다.
          </p>

          <h2>6. 개인정보 자동 수집 장치의 설치,운영 및 거부</h2>
          <p>
            고려해운은 회원에 대한 정보를 저장하고 수시로 찾아내는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트가 귀하의 컴퓨터 브라우저(넷스케이프, 인터넷 익스플로러 등)로 전송하는 소량의 정보로 귀하의 컴퓨터 하드디스크에 저장됩니다. 쿠키는 귀하의 컴퓨터는 식별하지만 귀하를 개인적으로 식별하지 않습니다.
          </p>
          <div class="ml15 mt15">
            <p>▷ 사용, 운영의 목적</p><p>
            </p><div>
              - 회원과 비회원의 접속빈도나 방문 시간 등을 분석하고 이용자의 취향과 관심분야 파악<br>
              - 회원 중심의 서비스 개편, 타겟(target) 마케팅 개량화 자료로 활용합니다.<br>
              - 회원이 관심 있는 뉴스, 주식종목 등 개인 맞춤 서비스를 제공하는 데 이용합니다.<br>
              - 회원의 컴퓨터를 찾아, 서비스 접속에 따른 이름 등의 추가정보입력 없이 서비스를 제공 합니다.
            </div>
            <p class="mt20">▷  쿠키 사용의 거부</p><p>
            </p><div>
              회원은 쿠키에 대한 선택권이 있습니다. 웹브라우저의 옵션을 조정함으로써 쿠키를 허용 하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.<br>
              단, 회원이 웹 브라우저의 쿠키 사용을 허용하지 않았을 경우에는 고려해운 제공하는 서비스 이용에 기술적 제약이 있을 수 있습니다.
            </div>
            <p class="mt20">설정방법의 예)</p>
            <div>
              * Internet Explorer을 사용하고 있는 경우<br>
              1. 웹 브라우저 상단 [도구] 메뉴 > [인터넷 옵션] <br>
              2. [개인정보]<br>
              3. [개인정보보호 수준] 설정
            </div>
            <p class="mt20">▷  Google Analytics</p>
            <div>
              고려해운은 고객에게 더 나은 서비스를 제공하기 위한 목적으로 Google, Inc. (이하 ‘Google’)이 제공하는 웹 분석 서비스인 Google Analytics를 사용하여 방문 고객들이 회사의 서비스를 어떻게 이용하는지 분석하여 고객의 수요를 파악하며, 서비스와 제품을 개선하고 개인화하여 효율적인 서비스를 제공하는 것에 목적이 있습니다.<br>
              <br>
              Google Analytics를 통해 수집되는 정보의 처리는 Google 개인정보보호정책과 Google Analytics 이용약관을 적용 받습니다.<br>
              Google 개인정보보호정책 : https://www.google.com/intl/ko/policies/privacy/<br>
              Google Analytics 이용약관 : https://www.google.com/analytics/terms/kr.html<br>
              <br>
              고려해운은 Google Analytics를 통해 익명의 쿠키 정보를 수집합니다.<br>
              또한, 고려해운은 Google Analytics를 통해 귀하가 이미 eKMTC 회원 가입 및 Profile 생성 시 등록한 고객 정보만을 수집합니다.
            </div>
          </div>

          <h2>7. 개인정보관리책임자</h2>
          <p>
            고려해운 은 개인정보에 대한 의견수렴 및 불만처리를 담당하는 개인정보 관리책임자를 지정하고 있습니다. 개인정보와 관련한 문의사항이 있으시면 아래의 개인정보 관리책임자에게 연락 주시기 바랍니다. 귀하의 문의사항에 신속하고 충분한 답변을 드릴 것입니다.<br>
            개인정보 관리책임자는 다음과 같습니다.
          </p>
          <div class="ml15 mt15">
            성 명 : 박진생<br>
            소속/직위 : 정보전략팀 / 팀장<br>
            E-mail : jinsaeng@kmtc.co.kr<br>
            전화 : (02) 311 - 6038<br>
            Fax : (02) 6008 - 8006
          </div>

          <h2>8. 개인정보보호를 위한 기술적 대책</h2>
          <p>
            고려해운은 귀하의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적 대책을 강구하고 있습니다.
          </p>
          <div class="ml15 mt15">
            1) 귀하의 개인정보는 비밀번호에 의해 보호되며, 파일 및 전송 데이터를 암호화하거나 파일 잠금기능(Lock)을 사용하여 중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다.
            <br>2) 고려해운은 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.
            <br>3) 고려해운은 암호알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안장치(SSL 또는 SET)를 채택하고 있습니다.
            <br>4) 사용자 정보는 비밀번호에 의해 보호됩니다. 회사에서 특정하게 허락한 시스템관리자와 본인만이 수정/삭제할 수 있습니다. 따라서 비밀번호를 제3자에게 알려주어서는 안됩니다.
            회사 관계자는 서비스와 관련 없이 개인의 정보나 ID 비밀번호를 전화상이나 e-mail상으로 먼저 요구하지 않습니다. 공공장소에서 컴퓨터를 사용하거나 타인과 함께 컴퓨터를 공유할 경우 암호가 자동으로 저장되도록 설정하지 마시고 또한 작업을 마치신 후에는 로그아웃(Log-out)하시고 웹브라우져를 종료하는 개인정보 보호에 필요한 조치를 취하시기 바랍니다.
            <br>5) 인터넷 서비스에 있는 게시판이나 e-mail, 기타 방법을 통해 사용자가 의도적이든 의도적이지 않든 자발적으로 제공하는 개인정보를 타인이 수집 가공 악용 될 수 있고, 사용자 찾기에 등록된 개인 정보를 타인이 수집 가공 악용될 수 있다는 점을 염두에 두시기 바랍니다. 고려해운은 그렇게 공개된 개인정보에 대한 책임을 지지 않습니다.
          </div>

          <h2>9. 개인정보의 위탁처리</h2>
          <p>고려해운은 서비스 향상을 위해서 귀하의 개인정보를 외부에 위탁하여 처리할 수 있습니다.</p>
          <div class="ml15 mt15">
            1) 개인정보의 처리를 위탁하는 경우에는 미리 그 사실을 귀하에게 고지하겠습니다.
            <br>2) 개인정보의 처리를 위탁하는 경우에는 위탁계약 등을 통하여 서비스제공자의 개인정보 보호 관련 지시엄수, 개인정보에 관한 비밀유지, 제3자 제공의 금지 및 사고시의 책임부담 등을 명확히 규정하고 당해 계약내용을 서면 또는 전자적으로 보관하겠습니다.
          </div>

          <h2>10. 의견수렴 및 불만처리</h2>
          <p>
            고려해운은 개인정보보호와 관련하여 귀하가 의견과 불만을 제기할 수 있는 창구를 개설하고 있습니다. 개인정보와 관련한 불만이 있으신 분은 고려해운의 개인정보 관리책임자에게 의견을 주시면 접수 즉시 조치하여 처리결과를 통보해 드립니다. 또한 개인정보침해에 대한 신고?상담이 필요하신 경우에는 정보통신부 산하 공공기관인 한국정보보호진흥원(KISA)내 개인정보침해신고센터로 문의하시기 바랍니다.
          </p>
          <div class="ml15 mt15">
            개인정보침해신고센터<br>
            - 전화 : 국번없이 1336<br>
            - E-mail: privacy@kisa.or.kr <br>
            - URL : http://www.1336.or.kr
          </div>

          <h2>11. 아동의 개인정보보호</h2>
          <p>
            아동의 개인정보를 보호하기 위하여 만 14세 미만의 아동이 회원 가입을 신청할 경우 법정대리인(부모)의 동의가 있어야 합니다. 따라서 만14세 미만의 어린이는 자신에 대한 정보를 다른 사람에게 함부로 보내면 안되며, 보내기 전에 반드시 법정대리인(부모님)의 허락을 받아야 합니다. 고려해운은 만14세 미만 어린이의 정보를 다른 사람 또는 업체와 공유하지 않으며, 만14세 미만의 어린이 에게는 광고메일을 발송하지 않습니다.
          </p>

          <h2>12. 개인정보보호에 대한 책임의 소재 확인</h2>
          <p>
            이상의 개인정보 보호정책에 열거한 사항 이외의 방법으로 제3자에게 개인정보가 유출, 침해를 당한 사항에 대해서는 회사에서 책임을 지지 않습니다.
          </p>

          <h2>13. 정책 변경에 따른 공지의무</h2>
          <p>
            이 개인정보보호방침은 2007년 10월 26일에 개정 되었으며 법령?정책 또는 보안기술의 변경에 따라 내용의 추가?삭제 및 수정이 있을 시에는 변경되는 개인정보보호정책을 시행하기 최소 10일전에 고려해운 홈페이지를 통해 변경이유 및 내용 등을 공지하도록 하겠습니다.
          </p>

          <h1>제2장 개인정보의 수집 및 이용 목적</h1>
          <p>
            1. 고려해운은 회원가입 고객에게 e-Booking, e-B/L Check 등 다양하고 편리한 인터넷 서비스를 제공하기 위하여 개인정보를 수집하고 있습니다. <br>
            고려해운은 수집된 정보를 바탕으로 보다 나은 서비스 제공을 위해 다양한 방법(전화, 안내문, 메일 등)을 통해 서비스 관련 정보를 제공할 수 있습니다.
          </p>
          <div class="ml15 mt15">
            가. 회원정보 관리의 목적<br>
            <p class="ml20"> : 회원제 서비스 이용 및 제한적 본인 확인제에 따른 본인확인, 개인식별, 비인가 회원의 부정 이용방지와 사용방지, 가입의사 확인, 만14세 미만 아동의 가입방지, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달</p>
            나. 신규 서비스 개발 및 마케팅, 자사 광고에의 활용 <br>
            <p class="ml20"> : 신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공, 자사 광고 게재, 서비스의 유효성 확인, 이벤트 및 광고성 정보 제공, 접속빈도 파악, 회원의 서비스이용에 대한 통계</p>
          </div>

          <p class="mt20">
            2. 고려해운은 이용자를 대상으로 해당 서비스의 양적, 질적 향상을 위하여 이용자의 개인 식별이 가능한 개인정보를 이용자의 동의를 받아 이를 수집하며, 맞춤서비스, e-Booking, e-Shipping Instruction, e-Delivery Order 등에 이용할 수 있습니다.
          </p>

          <p class="mt20">
            3. 고려해운은 서버의 로그파일이나 기타 설문조사 등을 통하여 얻은 개인정보를 근거로 사용자들의 행동양식, 관심사, 필요내용 등을 연구하여 더욱 좋은 서비스를 제공하기 위해 노력합니다.
          </p>
          <div class="mt30">
            개인정보보호정책 시행일자: 2001-01-02 <br>
            변경 시행일 : 2007-10-26
          </div>
        </div>
      </div><!-- content_box // -->
      <div class="mt10 text_center">
        <a
          class="button gray lg"
          href="#"
          @click.prevent="$emit('close')"
        >닫기</a>
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
export default {
  name: 'InformHandlePolicyPop'
}
</script>
